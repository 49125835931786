<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const companyData = runtimeConfig.public.company
</script>

<template>
  <div class="flex items-center">
    <div class="flex gap-1">
      <NuxtImg src="/team/team_1.jpg" alt="Team 1" class="h-4 w-4 rounded-full" />
      <NuxtImg src="/team/team_2.jpg" alt="Team 2" class="h-4 w-4 rounded-full" />
      <NuxtImg src="/team/team_3.jpg" alt="Team 3" class="h-4 w-4 rounded-full" />
      <div>
        <p class="text-xs">
          {{ $t('trustable.contact_agent') }} |  {{ $t('trustable.working_hours') }}
        </p>
      </div>
      <div>
        <p class="text-xs text-gray-300">
          |
        </p>
      </div>
      <div class="flex text-sm text-gray-900">
        <NIcon icon="ph:phone mr-1" />
        <a href="tel:{{ companyData.phoneNumber }}">
          {{ companyData.phoneNumber }}
        </a>
      </div>
    </div>
  </div>
</template>
