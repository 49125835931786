<script>
export default {
  name: 'ShopBenefitRow',
  components: {},
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    description: {
      type: String,
      required: true,
      default: '',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div class="uzo-feature-vertical-horizontal mb-2 mt-2">
    <div v-if="!vertical" class="uzo_feature relative flex items-center rounded-lg bg-white px-2 space-x-3">
      <slot name="icon" v-bind="{ icon }">
        <div class="feature-image flex-shrink-0">
          <NuxtImg class="h-10 w-10" :src="icon" alt="" width="50px" />
        </div>
      </slot>
      <slot name="text" v-bind="{ title, description }">
        <div class="min-w-0 flex-1">
          <span class="absolute inset-0" aria-hidden="true" />
          <slot name="title" v-bind="{ title }">
            <p class="mt-2 text-sm font-medium text-gray-900">
              {{ title }}
            </p>
          </slot>
          <slot name="description" v-bind="{ description }">
            <p class="text-sm text-gray-500">
              {{ description }}
            </p>
          </slot>
        </div>
      </slot>
    </div>
    <div v-else class="uzo-feature-vertical">
      <div class="flex flex-1 flex-col p-4">
        <slot name="icon" v-bind="{ icon }">
          <NuxtImg
            class="vertical-image mx-auto h-32 w-32 flex-shrink-0"
            :src="icon"
            alt=""
          />
        </slot>
        <slot name="text" v-bind="{ title, description }">
          <slot name="title" v-bind="{ title }">
            <h3 class="text-md text-center font-semibold text-gray-900">
              {{ title }}
            </h3>
          </slot>
          <slot name="description" v-bind="{ description }">
            <dl class="mt-1 flex flex-grow flex-col justify-between">
              <dd class="text-center text-sm text-gray-500">
                {{ description }}
              </dd>
            </dl>
          </slot>
        </slot>
      </div>
      <div />
    </div>
  </div>
</template>

<style scoped>
.feature-image {
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 15px;
}
.vertical-image{
  max-width: 80px;
}
</style>
