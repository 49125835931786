<script setup lang="ts">

</script>

<template>
  <div class="mx-auto max-w-[88rem] w-full px4 lg:(px8 py2) sm:(px-6)">
    <div class="relative max-h-80 w-full flex items-center overflow-hidden rounded-md bg-gray-200 p-5 md:max-h-40 md:flex-row">
      <!-- Image Section -->
      <div class="w-1/3 flex justify-center pr-10 pr-5 -mt-20 md:mt-9 md:w-1/2 md:pr-5">
        <NuxtImg src="/mockup.png" class="ml-4 max-w-full min-w-50 md:ml-4 sm:ml-0 md:max-w-1/2" />
      </div>

      <!-- Text Section -->
      <div class="mt-5 flex flex-col md:mt-0">
        <h3 class="rounded-md bg-gray-200 p-2 text-sm font-bold text-black md:bg-none md:p-0 md:text-xl">
          {{ $t('trustable.preparing_app_for_you') }}
        </h3>
        <h4 class="mt-0 p-2 text-xs font-light text-black md:mt-2 md:p-0 md:text-sm">
          {{ $t('trustable.soon_in_your_phone') }}
        </h4>
        <div class="mt-0 flex p-2 md:mt-3 space-x-4 md:p-0">
          <NLogo :logo="`app-store-badge-${$i18n.locale}`" n="3xl" />
          <NLogo :logo="`google-play-badge-${['cs', 'en'].includes($i18n.locale) ? $i18n.locale : 'en'}`" n="3xl" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
