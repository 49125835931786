<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'

const { t } = useI18n()

const trustees = [
  {
    text: t('trustable.free_shipping'),
    iconCode: 'ph:truck',
  },
  {
    text: t('trustable.express_24_shipping'),
    iconCode: 'ph:package',
  },
  {
    text: t('trustable.days_return'),
    iconCode: 'ph:arrows-clockwise',
  },
  {
    text: t('trustable.gift_for_every_order'),
    iconCode: 'ph:gift',
  },
  {
    text: t('trustable.free_return_and_dispute'),
    iconCode: 'ph:arrow-counter-clockwise',
  },
  {
    text: t('trustable.competition_for_prizez'),
    iconCode: 'ph:trophy',
  },
  {
    text: t('trustable.secure_web'),
    iconCode: 'ph:lock',
  },
  {
    text: t('trustable.more_than_100000_satisfied_customers'),
    iconCode: 'ph:users-three',
  },
]
</script>

<template>
  <Swiper
    direction="horizontal"
    :modules="[Autoplay]"
    :pagination="{ clickable: false }"
    :slides-per-view="1"
    :space-between="0"
    :autoplay="{ delay: 2500 }"
    loop
  >
    <SwiperSlide v-for="trustee in trustees">
      <div class="flex items-center justify-center text-black md:font-bold">
        <NIcon :icon="trustee.iconCode" class="h-7 w-7" /><span class="ml2 text-xs sm:text-sm">{{ trustee.text }}</span>
      </div>
    </SwiperSlide>
  </Swiper>
</template>
