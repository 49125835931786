<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const companyData = runtimeConfig.public.company
</script>

<template>
  <div class="fr">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="ph:buildings" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-semibold">
                {{ companyData.name }}
              </p>
              <p class="mt-1 text-xs">
                IČO: {{ companyData.id }}<br>
                DIČ: {{ companyData.vatId }}<br>
                <span class="mt-2 block font-semibold">Adresa společnosti:</span>
                {{ companyData.streetLine1 }}<br>
                {{ companyData.postalCode }} {{ companyData.city }}
                <span class="mt-2 block font-semibold">Bankovní spojení:</span>
                Číslo účtu: {{ companyData.bankAccountNumber }}<br>
                IBAN: {{ companyData.iban }}<br>
                Datová schránka: {{ companyData.databox }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sl">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start items-center">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="ph:envelope-light" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-semibold">
                Zákaznická podpora od 6:00 do 18:00
              </p>
              <p class="mt-1 text-xs">
                {{ companyData.emailAddress }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tl">
    <div class="flex items-end sm:items-start">
      <div class="w-full flex flex-col items-center sm:items-end space-y-4">
        <div class="max-w-sm w-full">
          <div class="flex items-start items-center">
            <div class="h-16 w-16 flex flex-shrink-0 items-center justify-center rounded-md bg-slate-100">
              <NIcon icon="ph:phone-call" class="font-xl block h-8 w-8" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="font-bold font-semibold">
                Telefonní linka
              </p>
              <p class="mt-1 text-xs">
                <a href="tel:{{ companyData.phoneNumber }}">
                  {{ companyData.phoneNumber }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
