import { upperFirst } from 'scule'

// @unocss-include
const SOCIALS_ICONS = {
  facebook: 'mdi:facebook',
  instagram: 'mdi:instagram',
  twitter: 'mdi:twitter',
  youtube: 'mdi:youtube',
}

const SOCIALS_BASE_URLS = {
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
  twitter: 'https://www.twitter.com/',
  youtube: 'https://www.youtube.com/',
}

export const useSocials = () => computed(() => Object.entries(useRuntimeConfig().public.socials)
  .filter(([key, value]) => value !== null && value !== '')
  .map(([key, value]) => ({
    label: upperFirst(key),
    icon: SOCIALS_ICONS[key],
    href: SOCIALS_BASE_URLS[key] + value,
  })),
)
