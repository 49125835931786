import type { SubPagesQuery } from '#graphql-operations'
export function fetchSubPages(){
    const state = useStore()
  
    return reactiveLoad<SubPagesQuery['subPages']['items']>(
      () => state.value.subPages,
      (subPages) => {
        state.value.subPages = subPages
      },
      () => useGraphqlQuery('subPages',{}).then(result => result.data.subPages.items),
    )
  }