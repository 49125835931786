<script>
export default {
  name: 'Blinker',
  props: {
    offset: {
      type: Number,
      default: 2,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getBlinkerRender() {
      const time = new Date()
      time.setHours(time.getHours() + this.offset)
      const rounded = new Date(Math.round(time.getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000))
      const formattedTime = rounded.toLocaleTimeString('cs-CZ', { hour: '2-digit', minute: '2-digit' })
      return formattedTime
    },
  },
}
</script>

<template>
  <div class="inline-flex items-center justify-between px-2 py1 sm:py-2" :class="{ 'shadow-md': shadow }">
    <span class="relative mr1 h-5 w-5 flex sm:mr-3">
      <span class="absolute h-full w-full inline-flex animate-ping rounded-full bg-green-200 opacity-75" />
      <span class="relative h-5 w-5 inline-flex rounded-full bg-green-500" />
    </span>
    <div class="text-bold ml1 font-medium text-context sm:ml3" n="base slate-600">
      <span class="no-wrap whitespace-nowrap font-bold" v-text="`${$t('trustable.blinker.actual_stock_busy', { n: offset })}`" />
      <div class="block text-xs">
        {{ $t('trustable.blinker.yours_order_will_be_ready_for_dispatch_at') }} {{ getBlinkerRender() }}.
        {{ $t('trustable.blinker.for_fastest_shipment_choose_card_payment') }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
